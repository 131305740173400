export async function newsletterSignup({
  languageId = 0,
  email = '',
  firstName = '',
  lastName = '',
  birthdayDate = '',
  weddingDate = '',
  isMale = true,
  eventName = '',
  countryId = 0,
  url = '',
  selectedType = '',
  utm_campaign = '',
  utm_content = '',
  utm_medium = '',
  utm_source = '',
}) {
  try {
    const response = await $fetch(`/api/marketing/newsletter-signup`, {
      method: 'POST',
      body: {
        LanguageId: languageId,
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        BirthdayDate: birthdayDate,
        WeddingDate: weddingDate,
        IsMale: isMale,
        EventName: eventName,
        CountryId: countryId,
        Url: url,
        SelectedType: selectedType,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
      },
    })

    return response || null
  } catch (error) {
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}
