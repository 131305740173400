<template>
  <div id="liveHelp">
    <button
      id="openLiveHelp"
      class="rounded-full bg-black live-help--toggler"
      :title="$t('have-question-chat', 'Have a question? Chat with us!')"
      @click="toggleLiveHelp"
    >
      <span id="liveNotificationBadge" class="liveNotificationBadgeClosed">
        <span id="liveNotificationBadgeNumberId" class="liveNotificationBadgeNumber"></span>
      </span>

      <div class="live-help--icons flex align-center justify-center">
        <i class="icon77 icon77-chat-sm -white"></i>
        <i class="icon77 icon77-whatsapp-sm -white"></i>
      </div>

      <span class="chat-notification-counter" style="display: none"></span>
    </button>

    <div v-if="isLiveHelpOpen" class="live-help--modal">
      <div class="live-help--content">
        <button type="button" class="close live-help--toggler" @click="toggleLiveHelp">
          <i class="icon77 icon77-exit"></i>
        </button>

        <h4 class="h4 mb-1">
          <i class="icon77 icon77-chat-black mr-2"></i>
          <span>{{ $t('chat-with-us', 'Chat with us') }}</span>
        </h4>

        <p class="body-14-aa">
          {{
            $t('nav-live-help-description', 'Our team of diamond and jewellery specialists are always here to help.')
          }}
        </p>

        <UiButton77
          id="openLiveChat"
          block
          :disabled="!kustomerChatWidget"
          class="live-help--toggler mt-4"
          @click="openChat2"
        >
          {{ $t('live-chat', 'live chat') }}
        </UiButton77>

        <UiButton77 variant="outlined" block class="live-help--toggler mt-4" :href="WHATSAPP_LINK" target="_blank"
          ><i class="icon77 icon77-whatsapp mr-1"></i> {{ $t('whatsapp-us', 'WhatsApp Us') }}</UiButton77
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { WHATSAPP_LINK } from '~/constants/social'
import { useLayoutStore } from '~/store/layoutStore'

const { kustomerChatWidget } = storeToRefs(useLayoutStore())

const isLiveHelpOpen = ref(false)

const toggleLiveHelp = () => {
  isLiveHelpOpen.value = !isLiveHelpOpen.value
}
</script>

<style lang="scss" scoped>
.chat-btn {
  @apply rounded-full;
  background-color: black;
}
.live-help--icons {
  > * {
    animation: changing-loop 10s ease 0s infinite;
    vertical-align: top;

    &:first-child {
      position: absolute;
      animation-delay: -2s;
    }

    &:nth-child(2) {
      animation-delay: -7s;
    }
  }
}

@keyframes changing-loop {
  0%,
  45%,
  100% {
    opacity: 1;
  }

  50%,
  95% {
    opacity: 0;
  }
}

#openLiveHelp {
  height: 48px;
  width: 48px;
  background-color: #0d0900;
  border-radius: 100%;
  margin-top: 16px;
  position: relative;
}

.live-help--modal {
  position: absolute;
  bottom: 48px;
  right: 0;
  background: #fff;
  width: calc(100vw - 20px);
  /*text-align: center;*/
  margin-bottom: 15px;
  box-shadow:
    0px 4px 12px -1px rgba(13, 9, 0, 0.1),
    0px 1px 4px -1px rgba(13, 9, 0, 0.06);

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -4px;
    right: 20px;
    transform: rotate(-45deg);
    z-index: 1000;
    background: #fff;
    box-shadow: -3px 3px 7px -2px rgba(13, 9, 0, 0.1);
  }
}

.live-help--content {
  max-height: 300px;
  max-height: calc(100vh - 115px);
  overflow: auto;
  padding: 16px;

  .close {
    position: absolute;
    /*background: url(/images/close.png) no-repeat center;*/
    background: none;
    width: 30px;
    height: 30px;
    right: 0;
    top: 0;
    border: 0;
    cursor: pointer;
  }
}

@media (min-width: 480px) {
  .live-help--modal {
    width: 300px;
  }
}
</style>
