<template>
  <div
    class="showroom-item"
    :class="{ open: displayItem, closest: isClosest }"
    @click="$emit('update:selected-showroom-id')"
  >
    <div class="showroomTitle">
      <h5 class="_h4 _text-uppercase" :class="{ 'caret-icon -small': !isClosest, '_mb-3': isClosest }">
        <template v-if="isClosest"> {{ $t('your-nearest-showroom', 'Your nearest showroom') }}</template>
        <template v-else>
          {{ showroom.City }}
          <span v-if="!showroom.IsOpen" class="newTag"> {{ $t('opening-soon', 'Opening Soon') }}</span>
        </template>
      </h5>
    </div>
    <Transition name="slide-showroom">
      <section v-if="displayItem">
        <div class="_row">
          <div class="_col-6 _col-md-5 showroom-address">
            <div class="showroom-more-info">
              <h6 v-if="isClosest" class="_h5 _text-uppercase _mb-2">{{ showroom.City }}</h6>
              <p class="_p address _light">
                {{ showroom.Address1 }}<br />{{ showroom.Address2 }}<br />{{ showroom.Address3 }}
              </p>
              <p class="_p _my-3 _light">
                <span class="_d-none _d-lg-block">{{ showroom.Phone }}</span>
                <span class="_d-lg-none">
                  <a :href="getShowroomPhoneHref(showroom.Phone)" class="rulertel dropdown-ignore">{{
                    showroom.Phone
                  }}</a>
                </span>
              </p>
              <nuxt-link
                v-if="showroom.IsOpen"
                :to="showroom.Direction"
                target="_blank"
                class="dropdown-ignore getDirectionsLink _cta-link"
              >
                {{ $t('get-directions', 'Get directions') }}
                <i class="icon77 icon77-share" style="vertical-align: top"></i>
              </nuxt-link>
            </div>
          </div>

          <div class="_col-6 _col-md-7 links showroom-more-info showroom-map">
            <iframe :src="showroom.EmbedUrl" width="100%" height="216" allowfullscreen></iframe>
          </div>
        </div>

        <div class="showroom-more-info">
          <p v-if="!showroom.IsOpen" class="_p _text-center _mb-0">
            {{ $t('showroom-first-open', 'Be the first to know when we open') }}
          </p>
          <button
            type="button"
            class="bookAppointmentShowroomBtn bookAppointment _btn-block _cta-white _rounded _mt-1 bg-charcoal text-white hover:bg-transparent hover:text-charcoal"
            :data-showroom-name="showroom.City"
            :data-showroom-id="showroom.Id"
            :data-showroom-open="showroom.IsOpen"
            @click="$emit('book-an-appointment')"
          >
            {{
              showroom.IsOpen
                ? $t('showroom-book', 'Book an Appointment')
                : $t('register-now', 'Register Now')
            }}
          </button>
          <p class="_p _my-3 _text-center">
            {{ $t('showroom-visit-by-appointment-only', 'Showroom visits are by appointment only.') }}
          </p>
        </div>
      </section>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import type { ClosestShowroomItem } from '../types'

const props = defineProps({
  showroom: {
    type: Object as PropType<ClosestShowroomItem>,
    required: true,
  },
  isSelected: Boolean,
  isClosest: Boolean,
})

const displayItem = computed(() => {
  return props.isSelected || props.isClosest
})

const getShowroomPhoneHref = (phone: string) => {
  // replace white spaces and parentheses
  return 'tel:' + phone.replace(/[\s()]/g, '')
}
</script>

<style scoped lang="scss">
.slide-showroom-enter-active,
.slide-showroom-leave-active {
  transition: max-height 0.5s ease;
  overflow: hidden;
}

.slide-showroom-enter-from,
.slide-showroom-leave-to {
  max-height: 0px;
}

.slide-showroom-enter-to {
  max-height: 500px; /* Adjust this value based on the content's maximum height */
}

.slide-showroom-leave-from {
  max-height: 500px; /* Adjust this value based on the content's maximum height */
}

.headerVisitShowroom #showroomlist div.showroom-item {
  padding-left: 32px;
  padding-right: 32px;
}

.headerVisitShowroom #showroomlist div.showroom-item.closest .showroomTitle {
  text-align: center;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
}

.headerVisitShowroom #showroomlist div.showroom-item:not(.closest).open .icon77.-down {
  transform: rotate(0deg);
}

.headerVisitShowroom .showroom-item {
  border-bottom: 1px solid lightgrey;
}

.headerVisitShowroom .showroom-item:not(.closest) {
  background-color: #f5f5f5;
  padding: 10px 0;
}

@media (max-width: 400px) {
  .headerVisitShowroom .showroom-item .showroom-address {
    width: 100%;
  }
  .headerVisitShowroom .showroom-item .showroom-map {
    width: 100%;
    padding-left: 0;
    padding-top: 12px;
  }
}

.headerVisitShowroom .showroom-item iframe {
  border: 1px solid black;
  padding: 1px;
}

.headerVisitShowroom .showroom-item h5 {
  cursor: pointer;
}

.headerVisitShowroom .showroom-item .showroom-more-info {
  padding-top: 15px;
}

.headerVisitShowroom .newTag {
  color: #fff;
  font-size: 8px;
  background-color: #000;
  padding: 4px 4px 2px;
  vertical-align: top;
}

.headerVisitShowroom .onlineConsultation {
  background: #ffeec9;
  padding: 20px 32px 25px 32px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .headerVisitShowroom .onlineConsultation {
    padding: 36px 60px;
  }
  .headerVisitShowroom .onlineConsultation ._h3 {
    font-size: 25px;
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .headerVisitShowroom .dropdown {
    width: 100%;
    padding: 0;
  }
  .headerVisitShowroom .showroom-item {
    padding: 0 16px;
  }
}

.caret-icon {
  position: relative;
}

.caret-icon:after {
  content: '';
  position: absolute;
  right: 3px;
  top: 50%;
  margin-top: -10px;
  width: 12px;
  height: 12px;
  transform: rotate(225deg);
  border: solid;
  border-width: 1.5px 0px 0px 1.5px;
}

.caret-icon.-small:after {
  margin-top: -6px;
  width: 8px;
  height: 8px;
}

.caret-icon.-up:after,
.open .caret-icon:after,
.active .caret-icon:after {
  transform: rotate(45deg);
  margin-top: 0px;
}
</style>
